import React from "react";
import { getUrlExtension } from '../utilities/helper'
import '../stylesheets/styles.css'

const AudioPlayer = props => {
    const url = props.url
    const ext = getUrlExtension(url)
    let type = ''
    if (ext === 'mp3') {
        type = 'audio/mp3'
    } else if (ext === 'm4a') {
        type = 'audio/m4a'
    }

    console.log('Audio url', url);
    return (<div className={props.className}>
        <h4 className="media-title">{props.title}</h4>
        <audio controls>
            <source src={url} type={type} />
        </audio>
    </div>)
}

export default AudioPlayer