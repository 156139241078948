import {
    FETCH_SONGS,
    FETCH_SONGS_SUCCESS,
    FETCH_SONGS_FAILED,
    SEARCH_SONGS,
    UPDATED_SELECTED_LANGS,
    CLEAR_SONGS,
} from "../actions/songs";

const initialState = {
    loadingSongs: false,
    allSongs: [],
    songs: [],
    availableLangs: [],
    selectedLangs: [],
    errorMessage: '',
}

const songs = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_SONGS:
            return {...state, loadingSongs: true}
        case FETCH_SONGS_SUCCESS:
            return {...state, loadingSongs: false, availableLangs: action.availableLangs, songs: action.songs, allSongs: action.songs}
        case FETCH_SONGS_FAILED:
            return {...state, loadingSongs: false}
        case SEARCH_SONGS:
            const songs = searchedSongs(action.searchKeyword.toLowerCase(), state.allSongs)
            return { ...state, songs }
        case UPDATED_SELECTED_LANGS:
            return {...state, selectedLangs: action.langs}
        case CLEAR_SONGS:
            return { ...state, songs: [], allSongs: [] }
        default:
            return state
    }
}

const searchedSongs = (searchKeyword, allSongs) => {
    if (searchKeyword === '') {
        return allSongs;
    }
    let songs = [];
    songs = allSongs.filter(song => ((song.song_name ?? '').toLowerCase().includes(searchKeyword) || (song.song_number ?? '').toLowerCase().includes(searchKeyword) || (song.song_firstline ?? '').toLowerCase().includes(searchKeyword) || (song.song_chorus ?? '').toLowerCase().includes(searchKeyword)))
    return songs;
}

export default songs