import { combineReducers } from "redux"
import { configureStore } from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import songs from "./reducers/songs";
import song from "./reducers/song";

const rootReducer = combineReducers({
    songs: songs,
    song: song,
})

const store = configureStore({middleware: [thunk], reducer: rootReducer})

export default store