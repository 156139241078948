import './App.css';
import { BrowserRouter } from 'react-router-dom';
import HomePage from './pages/Home';
import Router from './routes';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        {/* <HomePage /> */}
        <Router />
      </div>
    </BrowserRouter>
  );
}

export default App;
