import { URL_SONG } from "../../utilities/constants";

export const fetchSongDetail = async (songId) => {
    // console.log('fetch song api')
    
    const response = await fetch(URL_SONG(songId), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    
    if (!response.ok) {
        const errorResponse = await response.json();
        // console.log('ERROR THROW: ', errorResponse.message);
        throw new Error(errorResponse.message)
    }

    const resData = await response.json();
    // console.log('songs response')
    // console.log(resData.data.song)

    return resData.data.song;
}