import React from "react";
import '../stylesheets/styles.css'

const SongListItem = props => {
    const { item: song, index, onClick} = props
    
    let name = ''
    if ((song.song_name !== null) && (song.song_name !== undefined) && (song.song_name !== '')) {
        name = song.song_name
    } else if ((song.song_firstline !== null) && (song.song_firstline !== undefined) && (song.song_firstline !== '')) {
        name = song.song_firstline
    }//

    const bgClass = ((index % 2) === 0) ? "list-item-grey-bg" : "list-item-white-bg"
    const classes = `${bgClass} list-item`
    return (<div className="song-list-item">
        <ul className={`${bgClass} song-number`} onClick={onClick}>{song.song_number}</ul>
        <ul className={classes} onClick={onClick}>{name}</ul>
    </div>
    )
}

export default SongListItem