import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from 'rsuite';
import SongListItem from "../components/SongListItem";
import { fetchSongs, searchSongs, clearSongs } from "../store/actions/songs";
import '../stylesheets/styles.css'
import Popup from "reactjs-popup";
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import LanguageOptions from "../components/LanguageOptions";


const HomePage = props => {
    const {loadingSongs, songs, availableLangs} = useSelector(state => state.songs)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedLangs, setSelectedLangs] = useState([])


    document.title = 'Songs'
    // console.log('Home PAge,', selectedLangs);

    let host = 'Partaker'
    console.log('HOST:', window.location.hostname);
    if (window.location.hostname.includes('enjoyer')) {
        host = 'Enjoyer'
    }

    const fetchData = (searchQuery) => {
        dispatch(fetchSongs(searchQuery))
    }

    useEffect(() => {
        fetchData('')
    }, [dispatch])

    const searchSongHandler = searchKeyword => {
        dispatch(searchSongs(searchKeyword))
    }

    const handleOnClick = songItem => {
        
        // navigate('/detail', {state:{songId: songItem.id}})
        navigate(`/detail/${songItem.id}`)
    }

    const updateSelectedLangs = langs => {
        console.log('make updates in selected langs', langs);
        setSelectedLangs(langs)
        let searchquery = ''
        if (langs.length > 0) {
            searchquery += '?langs='
            for(const lang of langs) {
                searchquery += `${lang.code},`
            }
        }
        dispatch(clearSongs())
        fetchData(searchquery)
    }

    const LanguageSettingIcon = (<Popup trigger={<div><SettingsIcon className="setting-icon" /></div>} modal>
        {close => (<div className='overlay'><button className="close" onClick={close}>
          &times;
        </button><LanguageOptions availableLangs={availableLangs} selectedLangs={selectedLangs} updateSelectedLangs={updateSelectedLangs} close={close} /></div>)}
    </Popup>)

    const listItems = songs.map((s, i) => <SongListItem key={`${s.id}-${s.sort_order}`} item={s} index={i} onClick={handleOnClick.bind(this, s)} />);

    return(
        <div className="home-div">
            {LanguageSettingIcon}
            <div className="list-body">
                <h1>{host} Songs</h1>
                
                {(loadingSongs && songs.length === 0) ? <></> : <input className="search-input" placeholder="search here..." onChange={(e) => searchSongHandler(e.target.value)} />}
                {(loadingSongs && songs.length === 0) ? <Loader backdrop content="loading..." vertical /> : listItems}
            </div>
        </div>
    )
}

export default HomePage