import {
    FETCH_SONG_DETAIL,
    FETCH_SONG_DETAIL_SUCCESS,
    FETCH_SONG_DETAIL_FAILED,
} from "../actions/song";

const initialState = {
    loadingSongs: false,
    songDetail: null,
    errorMessage: '',
}

const song = (state = initialState, action) => {
    switch(action.type) {
        case FETCH_SONG_DETAIL:
            return {...state, loadingSongs: true, songDetail: null}
        case FETCH_SONG_DETAIL_SUCCESS:
            return {...state, loadingSongs: false, songDetail: action.songDetail}
        case FETCH_SONG_DETAIL_FAILED:
            return {...state, loadingSongs: false}
        default:
            return state
    }
}

export default song