import * as SongsReguests from '../requests/songs'

export const FETCH_SONGS = 'FETCH_SONGS'
export const FETCH_SONGS_SUCCESS = 'FETCH_SONGS_SUCCESS'
export const FETCH_SONGS_FAILED = 'FETCH_SONGS_FAILED'
export const SEARCH_SONGS = 'SEARCH_SONGS'
export const UPDATED_SELECTED_LANGS = 'UPDATED_SELECTED_LANGS'
export const CLEAR_SONGS = "CLEAR_SONGS"



export const fetchSongs = (searchQuery) => {
    // console.log('Action fetch songs');
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_SONGS})
            const data = await SongsReguests.fetchSongs(searchQuery)
            // console.log('SONGS:', songs);
            dispatch({type: FETCH_SONGS_SUCCESS, songs: data.songs ??[], availableLangs: data.available_langs ?? []})
        } catch(err) {
            // console.log('fetch songs catch:', err);
        }
    }
    // SongsReguests.fetchSongs()
    // return { type: FETCH_SONGS }
}

export const clearSongs = () => {
    return { type: CLEAR_SONGS }
}

export const searchSongs = searchKeyword => {
    // console.log('Action search song,', searchKeyword);
    return { type: SEARCH_SONGS, searchKeyword }
}

export const updateSelectedLangs = langs => {
    // console.log('Action search song,', searchKeyword);
    return { type: UPDATED_SELECTED_LANGS, langs }
}