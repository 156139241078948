import ReactPlayer from 'react-player'
import '../stylesheets/styles.css'

const YoutubePlayer = props => {
    const url = props.url

    return (<div className={props.className}>
        <h4 className="media-title">{props.title}</h4>
        <ReactPlayer url={url} controls={true} width='320px' height='180px' />
    </div>)
}

export default YoutubePlayer






