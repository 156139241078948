import { URL_SONGS } from "../../utilities/constants";

export const fetchSongs = async (searchQuery) => {

    const cacheDuration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    const cachedDataString = localStorage.getItem('cachedSongs');
    if (cachedDataString) {
        const cachedData = JSON.parse(cachedDataString);
        const currentTime = new Date().getTime();
        if (currentTime - cachedData.timestamp < cacheDuration) {
          // Data is still within the 24-hour cache window
          const data = cachedData.data;
          console.log('Using cached data:', data);
          return data.songs
        } else {
          // Data has expired, remove it from localStorage
          localStorage.removeItem('cachedSongs');
        }
    }

    const url = `${URL_SONGS}${searchQuery}`
    console.log('fetch songs api', url)
    try {
        const response = await fetch(url, {
            // mode: 'no-cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            console.log('!ok response,', response)
            const errorResponse = await response.json();
            console.log('ERROR THROW: ', errorResponse.message);
            throw new Error(errorResponse.message)
        }
    
        const resData = await response.json();
        console.log('songs response')
        console.log(resData)

        const songs = resData.data ?? {};

        const data = { songs };
        const timestamp = new Date().getTime(); // Current timestamp
    
        const cachedData = { data, timestamp };

        localStorage.setItem('cachedSongs', JSON.stringify(cachedData));
    
        return songs
    }  catch(err) {
        console.log('fetch songs res catch:', err);
        throw new Error(err.message)
    }
    
    
    
}