import React, { useState } from "react";
import '../stylesheets/styles.css'

const LanguageOptions = props => {
    const { availableLangs, selectedLangs, updateSelectedLangs, close } = props
    const [ langs, setLangs ] = useState(selectedLangs)

    console.log('langs:', langs);
    const onSelected = (lang) => {
        // console.log('Checkbox selected:', lang);
        let isContain = false
        for (const _lang of langs) {
            if(_lang.code === lang.code) {
                isContain = true
            }
        }

        if (isContain) {
            setLangs(langs.filter((l) => l.code !== lang.code))
        } else {
            setLangs([...langs, lang])
        }

    }

    const onApply = () => {
        updateSelectedLangs(langs)
        close()
    }

    const onClear = () => {
        updateSelectedLangs([])
        close()
    }

    const isSelected = lang => {
        if ((langs.length === 0) && (lang.code === 'en')) {
            return true
        }
        for (const _lang of langs) {
            if(_lang.code === lang.code) {
                return true
            }
        }
        return false
    }

    const Languages = availableLangs.map((al, i) => <div className="checkbox-input">
        <input
            type="checkbox"
            id={al.code}
            name={al.code}
            value={al.name}
            checked={isSelected(al)}
            onChange={onSelected.bind(this, al)}
        />
            {al.name}
  </div>)

    return(<div className="lang-bg">
        <h3>Select Language</h3>
        {Languages}
        <div className="apply-button-container">
            <button className="clear-button" type="button" onClick={onClear}>Clear Selection</button>
            <button className="apply-button" type="button" onClick={onApply}>Apply</button>
        </div>
    </div>)
}

export default LanguageOptions