import * as SongDetailReguests from '../requests/song'

export const FETCH_SONG_DETAIL = 'FETCH_SONG_DETAIL'
export const FETCH_SONG_DETAIL_SUCCESS = 'FETCH_SONG_DETAIL_SUCCESS'
export const FETCH_SONG_DETAIL_FAILED = 'FETCH_SONG_DETAIL_FAILED'



export const fetchSongDetail = (songId) => {
    // console.log('Action fetch song detail');
    return async (dispatch) => {
        try {
            dispatch({type: FETCH_SONG_DETAIL})
            const songDetail = await SongDetailReguests.fetchSongDetail(songId)
            // console.log('SONGS:', songs);
            dispatch({type: FETCH_SONG_DETAIL_SUCCESS, songDetail})
        } catch(err) {
            // console.log('fetch songs catch:', err);
        }
    }
    // SongsReguests.fetchSongs()
    // return { type: FETCH_SONG_DETAIL }
}