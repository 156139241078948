// const BASE_URL = 'https://members.enjoyer.life/api/'
const BASE_URL = 'https://members.enjoyer.life'
// const BASE_URL = ''
// export const URL_SONGS = `${BASE_URL}v1/songs`
export const URL_SONGS = `${BASE_URL}/api/react/v1/songs`

// export const URL_SONG = (id) => `${BASE_URL}v1/song/${id}`
export const URL_SONG = (id) => `${BASE_URL}/api/react/v1/song/${id}`



export const keys = ["A", "Bb", "B", "C", "Db", "D", "Eb", "E", "F", "Gb", "G", "Ab"];
export const bestGuessScale = ["A", "Bb", "B", "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#"];
export const scales = {
  A: ["A", "B", "C#", "D", "E", "F#", "G", "G#"],
  Bb: ["Bb", "C", "D", "Eb", "F", "G", "G#", "A"],
  B: ["B", "C#", "D#", "E", "F#", "G#", "A", "A#"],
  C: ["C", "D", "E", "F", "G", "A", "Bb", "B"],
  Db: ["Db", "Eb", "F", "Gb", "Ab", "Bb", "B", "C"],
  D: ["D", "E", "F#", "G", "A", "B", "C", "C#"],
  Eb: ["Eb", "F", "G", "Ab", "Bb", "C", "Db", "D"],
  E: ["E", "F#", "G#", "A", "B", "C#", "D", "D#"],
  F: ["F", "G", "A", "Bb", "C", "D", "D#", "E"],
  Gb: ["Gb", "Ab", "Bb", "Cb", "Db", "Eb", "E", "F"],
  G: ["G", "A", "B", "C", "D", "E", "F", "F#"],
  Ab: ["Ab", "Bb", "C", "Db", "Eb", "F", "Gb", "G"]
};
export const regex = {
  capo: /.*capo (\d+).*/i,
  // eslint-disable-next-line
  comment: /^\# ?(.*)/, // everything after a '#'
  comment2: /^\#! ?(.*)/, // everything after a '#'
  // eslint-disable-next-line
  chordWords: /([^\>\s]*\[[^\]]*?\][^\s<]*)/g, // a word with a chord in it
  chords: /\[(.*?)\]/g, // anything inside square brackets
  // eslint-disable-next-line
  chorus: /(\n|^)((  .*(?:\n|$))+)/g, // block with two spaces at the front of each line is a chorus
  hasChords: /.*\[.*\].*/, // has square brackets
  boldText: /\*\*(.+?)\*\*/g,
  italicText: /\*(.+?)\*/g,
  html_safety: /.*[<>`].*/,
  verseNumber: /(^|\n)([0-9]+)\n/gm, // numbers by themselves on a line are verse numbers
  chordCore: /([A-G]#?b?)([^A-G]*)/g,
  invisableUnicodeCharacters: /[\r\u2028\u2029]/g
};

//https://stackoverflow.com/questions/62335443/react-links-requested-url-was-not-found-on-this-server-after-being-deployed